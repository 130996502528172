import React, {createContext, useEffect, useState} from 'react';
import configg from "../config";

const SelectionContext = createContext({});

const SelectionProvider = ({children}) => {
    const [selectionData, setSelectionData] = useState(null);
    const [selectedFabric, setSelectedFabric] = useState(null)

    // this holds the user's selection for shirts
    const [jacketsData, setJacketsData] = useState({})

    useEffect(() => {
        // GET SHIRT DATA
        var formdata = new FormData();
        formdata.append("func", "3");
        var requestOptions = {
            method: 'POST',
            body: formdata,
            redirect: 'follow'
        };
        fetch(configg.link, requestOptions)
            .then(response => response.json())
            .then(result => {
                setSelectionData(result)
                setSelectedFabric(result.fabrics[0])
            })
            .catch(error => console.log('error', error));


    }, [])


    useEffect(() => {
        if (selectedFabric) {

            let newAttributes = selectionData.fabric_attributes.reduce((acc, attribute) => {
                let matchedOption = attribute.options.find(option => option.fabric_id === selectedFabric.id);
                if(matchedOption){
                    acc[attribute.type] = matchedOption;
                }
                return acc;
            }, {});
            console.log(newAttributes);
            setJacketsData(newAttributes)
        }

    }, [selectedFabric])


    const sizechart = [
        {
            title: "Shirt Size",
            short: "< 100cm",
            regular: "120cm - 180cm",
            long: "> 220cm",

            sizeList: [
                {
                    key: "XL",
                    regular: {
                        number: 10,
                        attributes: [
                            {
                                key: "Chest",
                                min: 100,
                                max: 140,
                                step: 0.5,
                                initial: 16.5

                            },
                            {
                                key: "Waist",
                                min: 10,
                                max: 20,
                                step: 0.5,
                                initial: 16.5
                            },
                        ],
                    },
                    short: {
                        number: 20,
                        attributes: [
                            {
                                key: "Chest",
                                min: 10,
                                max: 20,
                                step: 0.5,
                                initial: 16.5

                            },
                            {
                                key: "Waist",
                                min: 10,
                                max: 20,
                                step: 0.5,
                                initial: 16.5
                            },
                        ],
                    },
                    long: {
                        number: 30,
                        attributes: [
                            {
                                key: "Chest",
                                min: 10,
                                max: 20,
                                step: 0.5,
                                initial: 16.5

                            },
                            {
                                key: "Waist",
                                min: 10,
                                max: 20,
                                step: 0.5,
                                initial: 16.5
                            },
                        ],
                    }
                },
                {
                    key: "SMALL",
                    short: {
                        number: 10,
                        attributes: [
                            {
                                key: "Chest",
                                min: 10,
                                max: 20,
                                step: 0.5,
                                initial: 16.5

                            },
                            {
                                key: "Waist",
                                min: 10,
                                max: 20,
                                step: 0.5,
                                initial: 16.5
                            },
                        ],
                    },
                    long: {
                        number: 30,
                        attributes: [
                            {
                                key: "Chest",
                                min: 10,
                                max: 20,
                                step: 0.5,
                                initial: 16.5

                            },
                            {
                                key: "Waist",
                                min: 10,
                                max: 20,
                                step: 0.5,
                                initial: 16.5
                            },
                        ],
                    }
                },
            ],
            parameters: [
                "Chest",
                "Waist"
            ]
        }
    ]

    const [hasExtraTrousers, setHasExtraTrousers] = useState(false)
    const [sizeChart, setSizeChart] = useState(sizechart)

    const contextValue = {
        selectionData,
        setSelectionData,
        selectedFabric,
        setSelectedFabric,
        jacketsData, setJacketsData,
        hasExtraTrousers, setHasExtraTrousers,
        sizeChart, setSizeChart

    };

    return (
        <SelectionContext.Provider value={contextValue}>
            {children}
        </SelectionContext.Provider>
    );
};

export {SelectionProvider, SelectionContext};
