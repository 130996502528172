import {useContext, useState} from "react";
import Slider_left from "../Slider_left";
import {SelectionContext} from "../../../../context/UserSelectionContext";
import JacketTabSlider from "./JacketTabSlider";

export default function JacketTab() {
    const [openSidebar, setOpenSidebar]   = useState(false)
    const [sidebarData, setSidebarData]   = useState(null)
    const {selectionData, selectedFabric} = useContext(SelectionContext);
    const jacketsData = selectionData.fabric_attributes;

    return (
        <>
            <div className={"container-fluid tab"}>
                <div className="py-4 fw-light text-center h5 tab-heading">Customize your jacket</div>

                <div className="tab-column">
                    {jacketsData && jacketsData.map((item, key) => {
                        if (item.type !== "base"){
                            return (
                                <div className={`jacket-card`}
                                     key={key}
                                     onClick={() => {
                                         setSidebarData(item)
                                         setOpenSidebar(true)
                                     }}
                                >
                                    <div className="title">{item.type.replace("_", " ")}</div>
                                    <div className="option">{item.options[0].title}</div>
                                </div>
                            )
                        }

                    })}
                </div>

                <JacketTabSlider
                    sidebarData={sidebarData}
                    setOpenSidebar={setOpenSidebar}
                    openSidebar={openSidebar}
                />

            </div>
        </>
    )
}
