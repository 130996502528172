import {useContext, useState} from "react";
import {SelectionContext} from "../../../../context/UserSelectionContext";

export default function SummaryTab() {

    const {jacketsData, sizeChart} = useContext(SelectionContext);

    return (
        <>
            <div className={"container-fluid tab"}>
                <div className="py-4 fw-light text-center h5 tab-heading">Summary</div>

                <div className="tab-column">
                    <h6>Shirt</h6>
                    {jacketsData && Object.keys(jacketsData).map((item, key) => {
                        return (
                            <div className={`jacket-card`}
                                 key={key}
                            >
                                <div className="title">{item.replace("_", " ")}</div>
                                <div className="option">{jacketsData[item].title}</div>
                            </div>
                        )
                    })}

                    <br/>

                    <h6>Size</h6>
                    {sizeChart && sizeChart.map((item, key) => {
                        return (
                            <div className="card" key={key}>
                                <div className="card-body">
                                    {item.title}
                                </div>
                            </div>
                        )
                    })}

                    <br/>
                </div>
            </div>
        </>
    )
}
