import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import JacketTab from "./JacketTab/JacketTab";
import {useContext} from "react";
import {AppContext} from "../../../context/SuitBuilderContext";
import {SelectionContext} from "../../../context/UserSelectionContext";
import SizeTab from "./SizeTab/SizeTab";
import SummaryTab from "./Summary/SummaryTab";
import FabricTab from "./FabricTab";


export default function Sidebar({}) {
    const {setCurrentTab, currentTab} = useContext(AppContext);
    const {hasExtraTrousers} = useContext(SelectionContext);


    return (
        <>
            <Tabs onSelect={(index) => {
                setCurrentTab(index)
            }}
                  selectedIndex={currentTab}
            >
                <TabList className={"sidebar-tabs-list"}>
                    <Tab>1. Fabric</Tab>
                    <Tab>2. Style</Tab>
                    <Tab>6. Size</Tab>
                    <Tab>7. Summary</Tab>
                </TabList>


                <TabPanel><FabricTab/></TabPanel>
                <TabPanel><JacketTab/></TabPanel>
                <TabPanel><SizeTab/></TabPanel>
                <TabPanel><SummaryTab/></TabPanel>
            </Tabs>
        </>
    )
}
