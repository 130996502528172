import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './components/styles/global.scss';

import LayoutDefault from './components/layout';
import SuitBuilderRoot from "./pages/SuitBuilderRoot";
import {AppProvider} from "./context/SuitBuilderContext";
import {SelectionProvider} from "./context/UserSelectionContext";

function App() {
    return (
        <Router>
            <LayoutDefault>
                <Routes>
                    <Route path="/" element={
                        <AppProvider>
                            <SelectionProvider>
                                <SuitBuilderRoot/>
                            </SelectionProvider>
                        </AppProvider>
                    }/>
                </Routes>
            </LayoutDefault>
        </Router>
    );
}

export default App;
